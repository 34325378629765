import { Controller } from "@hotwired/stimulus"

const THROTTLE_DELAY = 100 // milliseconds

export default class extends Controller {
  static targets = ["button", "field"]

  connect() {
    this.element.classList.add("group/form");
  }

  clear() {
    this.fieldTargets.forEach((field) => {
      field.value = ""
    })
    this.submit()
  }

  loading() {
    // Set data-loading="true" on form element
    this.element.dataset.loading = true

    // Disable all of the buttons
    this.buttonTargets.forEach((button) => {
      button.disabled = true
      button.blur()
    })
  }

  submit(event) {
    this.loading()
    // Submit the form
    this.element.requestSubmit()
  }

  throttledSubmit() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.submit()
    }, THROTTLE_DELAY)
  }
}
