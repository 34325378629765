// Inspired by https://github.com/stimulus-components/stimulus-components/tree/master/components/scroll-to
// Modified to:
// - Update the # fragment in the URL after scrolling.
// - Set default offset to 16 instead of 10.

import { Controller } from "@hotwired/stimulus"

export default class ScrollToController extends Controller {
  initialize() {
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    this.element.addEventListener("click", this.scroll)
  }

  disconnect() {
    this.element.removeEventListener("click", this.scroll)
  }

  scroll(event) {
    event.preventDefault()

    const id = this.element.hash.replace(/^#/, "")
    const target = document.getElementById(id)

    if (!target) {
      console.warn(`[ScrollToController] The element with the id: "${id}" does not exist on the page.`)
      return
    }

    const elementPosition = target.getBoundingClientRect().top + window.pageYOffset
    const offsetPosition = elementPosition - this.offset

    window.scrollTo({
      top: offsetPosition,
      behavior: this.behavior,
    })

    window.history.replaceState(null, null, `#${id}`);
  }

  get offset() {
    if (this.hasOffsetValue) {
      return this.offsetValue
    }

    if (this.defaultOptions.offset !== undefined) {
      return this.defaultOptions.offset
    }

    return 16
  }

  get behavior() {
    return this.behaviorValue || this.defaultOptions.behavior || "smooth"
  }

  get defaultOptions() {
    return {}
  }
}
