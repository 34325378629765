import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class extends Controller {
  static outlets = ["sheet"]
  static values = {
    open: {
      type: Boolean,
      default: false
    }
  }

  connect() {
    useTransition(this, { transitioned: this.openValue })
  }

  show() {
    this.enter()
  }

  hide() {
    this.leave()
  }

  toggle() {
    this.toggleTransition();
  }

  closeSheet() {
    this.sheetOutlet.close()
  }
}
