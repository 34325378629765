import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class extends Controller {
  connect() {
    useTransition(this, {
      transitioned: true
    })
  }

  async hide() {
    await this.leave()
    this.element.remove()
  }
}
