// Turbo
import "@hotwired/turbo-rails"

// Stimulus
import { Application } from "@hotwired/stimulus"

// General controllers
import ScrollToController from "./controllers/scroll_to_controller"
// LUX controllers
import BackdropController from "./lux/controllers/backdrop_controller"
import BannerController from "./lux/controllers/banner_controller"
import FormController from "./lux/controllers/form_controller"
import SheetController from "./lux/controllers/sheet_controller"
import SheetToggleController from "./lux/controllers/sheet_toggle_controller"
import SnackbarController from "./lux/controllers/snackbar_controller"

const app = Application.start()

const controllers = [
  ["backdrop", BackdropController],
  ["banner", BannerController],
  ["form", FormController],
  ["scroll-to", ScrollToController],
  ["sheet", SheetController],
  ["sheet-toggle", SheetToggleController],
  ["snackbar", SnackbarController],
]

controllers.forEach((controller) => {
  app.register(...controller)
})
